var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('b-container',{staticStyle:{"padding":"100px 15px"},attrs:{"fluid":""}},[_c('div',[_c('b-row',{staticClass:"justify-content-center"},[_c('img',{staticClass:"logo",attrs:{"src":require("../../../../client/src/assets/Logo/ComodeIcon.svg")},on:{"click":function($event){return _vm.$router.push('/adminApp/login')}}})]),_c('div',{staticClass:"d-flex justify-content-center screen-title"},[_vm._v("ユーザー新規登録")]),_c('b-row',{staticClass:"pb-27 col-gap-30"},[_c('b-col',[_c('validation-observer',{ref:"formValidator",staticClass:"w-100",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var handleSubmit = ref.handleSubmit;
return [_c('b-form',{attrs:{"role":"form"},on:{"submit":function($event){$event.preventDefault();return handleSubmit(_vm.onSubmit)}}},[_c('card',{staticClass:"my-0 w-100 d-flex justify-content-center"},[_c('b-row',{staticClass:"custom__mx-3 pb-27 col-gap-30"},[_c('div',{staticClass:"col-md col-xs-12 px-0"},[_c('base-input',{attrs:{"alternative":"","placeholder":"会社/屋号","name":"会社/屋号","disabled":_vm.disabled,"label":"会社/屋号","labelClasses":'custom-form-input-label',"inputClasses":'custom-input-class'},model:{value:(_vm.model.register_name),callback:function ($$v) {_vm.$set(_vm.model, "register_name", $$v)},expression:"model.register_name"}})],1),_c('div',{staticClass:"col-md col-xs-12 px-0"},[_c('base-input',{attrs:{"alternative":"","disabled":_vm.disabled,"placeholder":"メールアドレス","name":"メールアドレス","rules":{
												required: true,
												email: true,
												checkExist: [_vm.model.email_address, 'email_address', _vm.disabled],
											},"label":"メールアドレス","inputRequired":true,"labelClasses":'custom-form-input-label',"inputClasses":'custom-input-class'},model:{value:(_vm.model.email_address),callback:function ($$v) {_vm.$set(_vm.model, "email_address", $$v)},expression:"model.email_address"}})],1)]),_c('b-row',{staticClass:"custom__mx-3 pb-27 col-gap-30"},[_c('div',{staticClass:"col-md col-xs-12 px-0"},[_c('base-input',{attrs:{"alternative":"","placeholder":"担当者","name":"担当者","disabled":_vm.disabled,"label":"担当者","labelClasses":'custom-form-input-label',"inputClasses":'custom-input-class',"unit":"様"},model:{value:(_vm.model.responsible_name),callback:function ($$v) {_vm.$set(_vm.model, "responsible_name", $$v)},expression:"model.responsible_name"}})],1),_c('div',{staticClass:"col-md col-xs-12 px-0"},[_c('base-input',{attrs:{"alternative":"","placeholder":"電話番号","name":"電話番号","label":"電話番号","disabled":_vm.disabled,"labelClasses":'custom-form-input-label',"inputClasses":'custom-input-class',"numberCaption":true},model:{value:(_vm.model.phone_number),callback:function ($$v) {_vm.$set(_vm.model, "phone_number", $$v)},expression:"model.phone_number"}})],1)]),_c('b-row',{staticClass:"custom__mx-3 col-gap-30 pb-27"},[_c('div',{staticClass:"col-md col-xs-12 px-0"},[_c('base-input',{attrs:{"alternative":"","placeholder":"ログインID","name":"ログインID","rules":{
												required: true,
												checkExist: [_vm.model.username, 'username'],
												validate: [
													[_vm.model.username],
													'ログインIDは英数字しか設定できません',
													_vm.checkUserName ],
											},"label":"ログインID","inputRequired":true,"labelClasses":'custom-form-input-label',"inputClasses":'custom-input-class'},model:{value:(_vm.model.username),callback:function ($$v) {_vm.$set(_vm.model, "username", $$v)},expression:"model.username"}})],1),_c('div',{staticClass:"col-md col-xs-12 px-0"},[_c('base-input',{attrs:{"alternative":"","placeholder":"パスワード","name":"パスワード","type":"password","rules":{ required: true, validFormat: true },"label":"パスワード","inputRequired":true,"labelClasses":'custom-form-input-label',"inputClasses":'custom-input-class',"note":_vm.pwdRegex},model:{value:(_vm.model.login_password),callback:function ($$v) {_vm.$set(_vm.model, "login_password", $$v)},expression:"model.login_password"}})],1),_c('div',{staticClass:"col-md col-xs-12 px-0"},[_c('base-input',{attrs:{"alternative":"","placeholder":"確認パスワード","name":"確認パスワード","type":"password","rules":{
												required: true,
												confirmPassword: _vm.model.login_password,
											},"label":"確認パスワード","inputRequired":true,"labelClasses":'custom-form-input-label',"inputClasses":'custom-input-class'},model:{value:(_vm.model.confirmpassword),callback:function ($$v) {_vm.$set(_vm.model, "confirmpassword", $$v)},expression:"model.confirmpassword"}})],1)])],1),_c('div',{staticClass:"d-flex justify-content-center"},[_c('button',{staticClass:"mt-4 customButton customButton__submit",attrs:{"type":"submit"}},[_vm._v(" 登録 ")])])],1)]}}])})],1)],1)],1)])],1)}
var staticRenderFns = []

export { render, staticRenderFns }